import $ from "jquery";
import "./import/modules";

$(function () {
  //---Yandex.Metrika counter---//

   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(73266943, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });

// ---Yandex.Metrika  ---//

 function popalaxPosition(element, numMn){
  $('.container').mousemove(function(pos){
    var posX = pos.clientX * numMn
    var posY = pos.clientY * numMn
   
   $(element).css({
     "transform":`translate3d(${posX}px, ${posY}px, 0px)`
   })
  })
 }

 popalaxPosition('.content p span', -0.02)
 popalaxPosition('.game-training .img', 0.01)
 popalaxPosition('.stimul .img', 0.01)
 popalaxPosition('.osx', -0.02)


  // -------Защита от подсматривания------//
  // document.addEventListener("contextmenu", function (event) {
  //   event.preventDefault();
  // });
 // -------Защита от подсматривания конец------//
 $('body').css('overflow','hidden')

var finalCookie = {}


function startCookie(){
  var mainCookie = document.cookie.split('; ')
  mainCookie.forEach(elem =>{
    finalCookie[elem.split('=')[0]] = elem.split('=')[1]
   })
}
startCookie();

  if(finalCookie["who"]){
    stileParent()
  }

  function stileParent(){
    $('body').css('overflow','auto')
    $('.parent').css('display', 'none')
  }

 function eventParent(string){
  stileParent()
  document.cookie ='who='+string
 }
 $('.btn-parent').click(function(e){
  eventParent('родитель')
  startCookie();
 })
 $('.btn-children').click(function(e){
  eventParent('ребенок')
  startCookie();
 })


  $(".start-popup-pay").click(function (e) {
    e.preventDefault();

    var title = e.target.parentElement.querySelector("h3").innerText;
    var price = e.target.parentElement.parentElement.querySelector(
      ".img>p>span"
    ).innerHTML;
    var formcomment = $('.form input[name="formcomment"]');
    var shortDest = $('.form input[name="short-dest"]');
    var targets = $('.form input[name="targets"]');
    var sum = $('.form input[name="sum"]');
    formcomment.val(title);
    shortDest.val(title);
    targets.val(title);
    sum.val(price);
    $(".title-form").text('Заказать "' + title + '"');

    $(".popUp-form-pay").css("display", "flex");
    setTimeout(function () {
      $(".form").css("transform", "scale(1)");
    }, 100);
  });
  $(".form__close").click(function () {
    $(".popUp-form-pay").hide();
    $(".form").css("transform", "scale(0)");
  });

  $(".start-popup").click(function (e) {
    e.preventDefault();
    if (e.target.parentElement.querySelector("h3")) {
      var title = e.target.parentElement.querySelector("h3").innerText;
      $(".title-form").text('Заказать "' + title + '"');
    }
    $(".popUp-form").css("display", "flex");
    setTimeout(function () {
      $(".form").css("transform", "scale(1)");
    }, 100);
  });
  $(".popUp-form form").submit(function (e) {
    e.preventDefault();
    let form_data = $(this);
    $(".preloader").css("display", "flex");
    var tel = $('.input-tel').val()
    var re = /^((\+7|7|8)+([0-9]){10})$/
    var itogData = form_data.serialize()+'&who='+finalCookie["who"]
    if(finalCookie["who"]==='родитель' && finalCookie["be"]!=='true' && re.test(tel)){
      ym(73266943,'reachGoal','zakaz')
    }
    document.cookie ='be=true';
    $.ajax({
      type: "POST",
      url: form_data.attr("action"),
      data: itogData,
    }).always(function (response) {
      $(".popUp-form").css("display", "none");
      $(".preloader").css("display", "none");
      $(".sank").css("display", "flex");
      form_data[0].reset()
      setTimeout(function () {
        $(".sank").css("display", "none");
      }, 3000);
    });
  });

  $(".form__close").click(function () {
    $(".popUp-form").hide();
    $(".form").css("transform", "scale(0)");
  });

});
